
export function EmailData() {
    return (

        <div id="contact">
            <div className="bg-custom-background-white flex flex-row mx-[10%] justify-center">
                <div className='bg-custom-bright-white flex sm:flex-row flex-col border-[0.5px] rounded-button sm:p-10 px-5 pb-5 w-full '>
                    <div className='w-full sm:pt-[0%] pt-[5%]'>
                        <div className='flex sm:flex-row flex-col w-full relative '>
                            <div className='flex sm:flex-row flex-col  w-full '>
                                <div className='sm:w-1/2 w-full sm:pb-0 pb-[5%] flex sm:flex-col flex-row'>
                                    <p className="tracking-wide font-custom-m lg:text-4xl sm:text-3xl text-1xl text-custom-orange text-left">
                                        OUR EMAIL
                                    </p>
                                </div>
                                <div className='sm:w-1/2 w-full'>
                                <p className="tracking-wide font-custom-r font-light  xs:text-1xl text-xl text-custom-black pb-2">
                                        info@wm-supernova.com
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}


