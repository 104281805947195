
import { ContactForm } from './ContactForm';

export function Contacts() {
    return (
            <div className="bg-custom-background-white flex flex-row mx-[10%] justify-center">
                <div className='bg-custom-bright-white flex sm:flex-row flex-col border-[0.5px] rounded-button sm:p-10 px-5 pb-5 w-full '>
                    <div className='w-full sm:pt-[0%] pt-[5%]'>
                        <ContactForm />
                    </div>
                </div>
            </div>
    );
}