import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { TEAHouseCafe } from './TEAHouseCafe';
import { CleanKeepers } from './CleanKeepers';
import { YourSwimwear } from './YourSwimwear';
import { DrJart } from './DrJart';
import { MythicalFinland } from './MythicalFinland';
import { ExoticFruitsShop } from './ExoticFruitsShop';
import { InkMasterpiece } from './InkMasterpiece';
import ArrowButton from "../assets/ArrowButton.svg";
//import Orange2 from '../assets/Ellipse2.png';

export function OurProjects() {
    const [showModal, setShowModal] = useState('');
    const [isHovered, setIsHovered] = useState([false, false, false, false, false, false, false]);

    const handleMouseEnter = (index) => {
        const newIsHovered = [...isHovered];
        newIsHovered[index] = true;
        setIsHovered(newIsHovered);
    }

    const handleMouseLeave = (index) => {
        const newIsHovered = [...isHovered];
        newIsHovered[index] = false;
        setIsHovered(newIsHovered);
    }

    return (
        <div id='projects'>
            {showModal === 'TEAHouseCafe' &&
                createPortal(
                    <TEAHouseCafe onClose={() => setShowModal('')} />,
                    document.body,
                )}
            {showModal === 'CleanKeepers' &&
                createPortal(
                    <CleanKeepers onClose={() => setShowModal('')} />,
                    document.body,
                )}
            {showModal === 'InkMasterpiece' &&
                createPortal(
                    <InkMasterpiece onClose={() => setShowModal('')} />,
                    document.body,
                )}
            {showModal === 'YourSwimwear' &&
                createPortal(
                    <YourSwimwear onClose={() => setShowModal('')} />,
                    document.body,
                )}
            {showModal === 'MythicalFinland' &&
                createPortal(
                    <MythicalFinland onClose={() => setShowModal('')} />,
                    document.body,
                )}
            {showModal === 'ExoticFruitsShop' &&
                createPortal(
                    <ExoticFruitsShop onClose={() => setShowModal('')} />,
                    document.body,
                )}
            {showModal === 'DrJart' &&
                createPortal(
                    <DrJart onClose={() => setShowModal('')} />,
                    document.body,
                )}

            <div  className="bg-custom-background-white mx-[10%]">
                <div className="flex flex row items-center">
                    <div className='flex flex-col w-2/3'>
                        <p className="tracking-wide font-custom-m lg:text-4xl sm:text-3xl text-2xl text-custom-orange mb-1 ">
                            SOME OF
                        </p>
                        <p className="tracking-wide font-custom-m lg:text-4xl sm:text-3xl text-2xl text-custom-orange pl-[5%]">
                            OUR PROJECTS
                        </p>
                    </div>

                    <div className="flex flex-col items-center justify-center border-x-[0.5px] border-t-[0.5px] border-custom-dark-grey rounded-t-button rounded-tl-button w-1/3 h-[200px]">
                        <div className="flex flex-col justify-end h-1/2">
                        <p className="tracking-wide font-custom-r font-light md:text-2xl text-sm text-custom-light-black text-center px-2">TEA House Cafe</p>
                        </div>
                        <div
                            className="self-end justify-end flex h-1/2 cursor-pointer"
                            onMouseEnter={() => handleMouseEnter(0)}
                            onMouseLeave={() => handleMouseLeave(0)}
                            onClick={() => setShowModal('TEAHouseCafe')}
                        >
                            {isHovered[0] ? (
                                <p className="font-custom-r text-1xl text-custom-orange mr-6 mb-6 self-end">SHOW</p>
                            ) : (
                                <img
                                    src={ArrowButton}
                                    alt="button"
                                    className="sm:w-[40px] w-[25px] mr-6"
                                    loading="lazy"
                                />
                            )}
                        </div>
                    </div>

                </div>



                <div className="flex sm:flex-row ">
                    <div className="flex flex-col items-center justify-center border-t-l border-[0.5px] border-custom-dark-grey rounded-tl-button rounded-tl-button w-1/3 h-[200px]">
                        <div className="flex flex-col justify-end h-1/2">
                        <p className="tracking-wide font-custom-r font-light md:text-2xl text-sm text-custom-light-black text-center px-2">Clean Keepers</p>
                        </div>
                        <div
                            className="self-end justify-end flex h-1/2 cursor-pointer"
                            onMouseEnter={() => handleMouseEnter(1)}
                            onMouseLeave={() => handleMouseLeave(1)}
                            onClick={() => setShowModal('CleanKeepers')}
                        >
                            {isHovered[1] ? (
                                <p className="font-custom-r text-1xl text-custom-orange mr-6 mb-6 self-end">SHOW</p>
                            ) : (
                                <img
                                    src={ArrowButton}
                                    alt="button"
                                    className="sm:w-[40px] w-[25px] mr-6"
                                    loading="lazy"
                                />
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-center border-y-[0.5px] border-r-[0.5px] border-custom-dark-grey w-1/3 h-[200px]">
                        <div className="flex flex-col justify-end h-1/2">
                            <p className="tracking-wide font-custom-r font-light md:text-2xl text-sm text-custom-light-black text-center px-2">Ink Masterpiece</p>
                        </div>
                        <div
                            className="self-end justify-end flex h-1/2 cursor-pointer"
                            onMouseEnter={() => handleMouseEnter(2)}
                            onMouseLeave={() => handleMouseLeave(2)}
                            onClick={() => setShowModal('InkMasterpiece')}
                        >
                            {isHovered[2] ? (
                                <p className="font-custom-r text-1xl text-custom-orange mr-6 mb-6 self-end">SHOW</p>
                            ) : (
                                <img
                                    src={ArrowButton}
                                    alt="button"
                                    className="sm:w-[40px] w-[25px] mr-6"
                                    loading="lazy"
                                />
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-center border-y-[0.5px] border-r-[0.5px] border-custom-dark-grey rounded-br-button w-1/3 h-[200px]">
                        <div className="flex flex-col justify-end h-1/2">
                        <p className="tracking-wide font-custom-r font-light md:text-2xl text-sm text-custom-light-black text-center px-2">Your Swimwear</p>
                        </div>
                        <div
                            className="self-end justify-end flex h-1/2 cursor-pointer"
                            onMouseEnter={() => handleMouseEnter(3)}
                            onMouseLeave={() => handleMouseLeave(3)}
                            onClick={() => setShowModal('YourSwimwear')}
                        >
                            {isHovered[3] ? (
                                <p className="font-custom-r text-1xl text-custom-orange mr-6 mb-6 self-end">SHOW</p>
                            ) : (
                                <img
                                    src={ArrowButton}
                                    alt="button"
                                    className="sm:w-[40px] w-[25px] mr-6"
                                    loading="lazy"
                                />
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex flex row">
                    <div className="flex flex-col items-center justify-center border-x-[0.5px] border-b-[0.5px] border-custom-dark-grey rounded-bl-button w-1/3 h-[200px]">
                        <div className="flex flex-col justify-end h-1/2">
                        <p className="tracking-wide font-custom-r font-light md:text-2xl text-sm text-custom-light-black text-center px-2">Mythical Finland</p>
                        </div>
                        <div
                            className="self-end justify-end flex h-1/2 cursor-pointer"
                            onMouseEnter={() => handleMouseEnter(4)}
                            onMouseLeave={() => handleMouseLeave(4)}
                            onClick={() => setShowModal('MythicalFinland')}
                        >
                            {isHovered[4] ? (
                                <p className="font-custom-r text-1xl text-custom-orange mr-6 mb-6 self-end">SHOW</p>
                            ) : (
                                <img
                                    src={ArrowButton}
                                    alt="button"
                                    className="sm:w-[40px] w-[25px] mr-6"
                                    loading="lazy"
                                />
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-center border-r-[0.5px] border-b-[0.5px] border-custom-dark-grey w-1/3 h-[200px]">
                        <div className="flex flex-col justify-end h-1/2">
                        <p className="tracking-wide font-custom-r font-light md:text-2xl text-sm text-custom-light-black text-center px-2">Exotic Fruits Shop</p>
                        </div>
                        <div
                            className="self-end justify-end flex h-1/2 cursor-pointer"
                            onMouseEnter={() => handleMouseEnter(5)}
                            onMouseLeave={() => handleMouseLeave(5)}
                            onClick={() => setShowModal('ExoticFruitsShop')}
                        >
                            {isHovered[5] ? (
                                <p className="font-custom-r text-1xl text-custom-orange mr-6 mb-6 self-end">SHOW</p>
                            ) : (
                                <img
                                    src={ArrowButton}
                                    alt="button"
                                    className="sm:w-[40px] w-[25px] mr-6"
                                    loading="lazy"
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex flex row justify-center">
                    <div className="flex flex-col items-center justify-center border-x-[0.5px] border-b-[0.5px] border-custom-dark-grey rounded-b-button w-1/3 h-[200px]">
                        <div className="flex flex-col justify-end h-1/2">
                        <p className="tracking-wide font-custom-r font-light md:text-2xl text-sm text-custom-light-black text-center px-2">Dr. Jart+</p>
                        </div>
                        <div
                            className="self-end justify-end flex h-1/2 cursor-pointer"
                            onMouseEnter={() => handleMouseEnter(6)}
                            onMouseLeave={() => handleMouseLeave(6)}
                            onClick={() => setShowModal('DrJart')}
                        >
                            {isHovered[6] ? (
                                <p className="font-custom-r text-1xl text-custom-orange mr-6 mb-6 self-end">SHOW</p>
                            ) : (
                                <img
                                    src={ArrowButton}
                                    alt="button"
                                    className="sm:w-[40px] w-[25px] mr-6"
                                    loading="lazy"
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
