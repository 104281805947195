import { useForm, ValidationError } from '@formspree/react';
import PaperPlane from '../assets/PaperPlane.svg';

export function ContactForm() {
    const [state, handleSubmit] = useForm("meojwlzo");
    if (state.succeeded) {
        return (
            <div className='flex flex-col items-center justify-center'>
        <p className=" font-custom-m text-2xl text-custom-orange text-center ">We've got your email and</p>
        <p className=" font-custom-m text-2xl text-custom-orange text-center">will contact you soon!</p>
            </div>
        )
    }

    return (
        <div className='flex sm:flex-row flex-col w-full relative '>
            <form onSubmit={handleSubmit} className='flex sm:flex-row flex-col  w-full '>
            <div className='sm:w-1/2 w-full sm:pb-0 pb-[5%] flex sm:flex-col flex-row'>
                <p className="tracking-wide font-custom-m lg:text-4xl sm:text-3xl text-1xl text-custom-orange mb-1 text-left pl-1">
                    CONTACT FORM
                </p>
            </div>
            <div className='sm:w-1/2 w-full'>
            <label htmlFor="name" className='self-start font-custom-r text-custom-light-black mb-1'>
                    Name
                </label>
                <input
                    id="name"
                    type="text"
                    name="name"
                    className='border-[0.8px] font-custom-r border-custom-light-black rounded-button p-2 w-full mb-4'
                />
                <ValidationError
                    prefix="Name"
                    field="name"
                    errors={state.errors}
                />
                <br />
                <label htmlFor="email" className='self-start font-custom-r text-custom-light-black mb-1'>
                    Email Address
                </label>
                <input
                    id="email"
                    type="email"
                    name="email"
                    className='border-[0.8px] font-custom-r border-custom-light-black rounded-button p-2 w-full mb-4'
                />
                <ValidationError
                    prefix="Email"
                    field="email"
                    errors={state.errors}
                />
                <br />
                <label htmlFor="message" className='self-start font-custom-r text-custom-light-black mb-1'>
                    Message
                </label>
                <textarea
                    id="message"
                    name="message"
                    className='border-[0.8px] font-custom-r border-custom-light-black rounded-button p-2 w-full mb-4'
                />
                <ValidationError
                    prefix="Message"
                    field="message"
                    errors={state.errors}
                />
                <button type="submit" disabled={state.submitting}
                    className='text-1xl text-custom-bright-white bg-custom-orange rounded-button w-full py-[16px] mt-4'>
                    GET IN TOUCH
                </button>
                <input type="hidden" name="_language" value="en" />
                <input type="hidden" name="_next" value="https://wm-supernova.com" />
            </div>

            <div>
            <img src={PaperPlane} alt='paper plane' className='md:w-[300px] w-[250px] absolute z-[2] lg:top-[65%] lg:left-[-15%] md:top-[65%] md:left-[-30%] top-[70%] left-[-30%] absolute sm:block hidden' loading="lazy" />
            </div>
            </form>
        </div>
    )
}